import PropTypes from 'prop-types';
import React from 'react';
import ListItem from '../../atoms/ListItem/ListItem';
import { colors } from '../../../styles/base';

const List = ({ _key, list, subheadingColor }) => (
  <ul key={_key}>
    {list.map((listItem, index) => {
      const listDataIndex = `listData-${index}`;

      return (
        <ListItem
          listItemData={listItem.listItemLink}
          subheadingColor={subheadingColor}
          key={listDataIndex}
        />
      );
    })}
  </ul>
);

List.propTypes = {
  _key: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subheadingColor: PropTypes.string,
};

List.defaultProps = {
  _key: '',
  subheadingColor: colors.primary,
};

export default List;
