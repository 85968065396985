/**
 * Displays an item in a list.
 */
import PropTypes from 'prop-types';
import React from 'react';
import Subheading from '../Subheading/Subheading';
import Link from '../Link/Link';

import { colors, fontSizes } from '../../../styles/base';

const ListItem = ({
  button,
  listItemData,
  subheadingColor,
  subheadingTextHighlight,
}) => {
  const { link, text } = listItemData;

  if (link && link.length) {
    return (
      <li>
        <Link to={link}>
          <Subheading fontSize={fontSizes.twelve} color={subheadingColor}>
            {text}
            <span>
              &nbsp;
              {subheadingTextHighlight}
            </span>
          </Subheading>
        </Link>
      </li>
    );
  }
  if (button) {
    return (
      <li>
        <button type="button">
          <Subheading fontSize={fontSizes.twelve} color={subheadingColor}>
            {text}
            <span>
              &nbsp;
              {subheadingTextHighlight}
            </span>
          </Subheading>
        </button>
      </li>
    );
  }
  return (
    <li>
      <Subheading fontSize={fontSizes.twelve} color={subheadingColor}>
        {text}
        <span>
          &nbsp;
          {subheadingTextHighlight}
        </span>
      </Subheading>
    </li>
  );
};

ListItem.propTypes = {
  button: PropTypes.bool,
  listItemData: PropTypes.shape({
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    text: PropTypes.string,
  }),
  subheadingColor: PropTypes.string,
  subheadingTextHighlight: PropTypes.string,
};

ListItem.defaultProps = {
  button: false,
  listItemData: {},
  subheadingColor: colors.primary,
  subheadingTextHighlight: '',
};

export default ListItem;
