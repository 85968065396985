import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  colors,
  durations,
  unitConverter as uc,
} from '../../../../styles/base';

const Divider = ({
  color,
  hoverColor,
  hovered,
  marginBottom,
  marginTop,
  width,
}) => {
  const dividerCss = css`
    width: ${uc(width)};
    height: ${uc('5px')};
    margin: ${uc(marginTop)} auto ${uc(marginBottom)};
    background-color: ${hovered ? hoverColor : color};
    border-radius: ${uc('20px')};
    transform: ${hovered
      ? `translateY(${uc('-10px')}) scaleX(1.5)`
      : 'translateY(0)'};
    transition: transform 300ms ease-in-out,
      background-color ${durations.medium} ease-in-out;
  `;
  return <div css={dividerCss} />;
};

Divider.propTypes = {
  hovered: PropTypes.bool,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};

Divider.defaultProps = {
  hovered: false,
  color: colors.lightGray.three,
  hoverColor: colors.primary,
  width: `${uc('75px')}`,
  marginTop: `${uc('30px')}`,
  marginBottom: `${uc('30px')}`,
};

export default Divider;
