/**
 * <SuiteAvatarAnimated> Component
 *
 * Displays a Suite avatar with an 'outlined' prop so that it can be rendered
 * as filled in or as an outline
 */
/**
 *  this comment tells babel to convert jsx to calls to a function called jsx
 *  instead of React.createElement - needed for storybook.
 */
/** @jsx jsx */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import SVG from 'react-inlinesvg';
import { colors, durations, unitConverter as uc } from '../../../styles/base';

class SuiteAvatarAnimated extends Component {
  /**
   * Magic
   *
   * @param props
   *  outlined {bool} | default: FALSE
   *  avatar {string} | default: ''
   *  width {string} | default: '100px'
   */
  constructor(props) {
    super(props);

    this.state = {
      suiteType: props.suiteType,
      width: props.width,
    };
  }

  /**
   * Render the component.
   *
   * @return {*}
   */
  render() {
    const { suiteType, width } = this.state;

    /**
     * Styles for the default version of the avatar.
     */
    const avatarDefaultCss = css`
      width: ${uc(width)};
      margin: 0 auto;

      &:hover {
        fill: none;
      }

      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
        stroke-width: 4;

        path {
          transition: all ${durations.medium} ease-in-out;
        }

        .st0 {
          fill: ${colors[suiteType].avatar.primary};
        }

        .st1 {
          fill: ${colors[suiteType].avatar.accent};
        }
      }
    `;

    /**
     * Styles for the outlined version of the avatar.
     */
    const avatarOutlinedCss = css`
      width: ${uc(width)};
      margin: 0 auto;

      &:hover {
        fill: none;
      }

      svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
        stroke-width: 4;

        path {
          transition: all ${durations.medium} ease-in-out;
        }

        .st0 {
          fill: transparent;
          stroke: none;
        }

        .st1 {
          fill: transparent;
          stroke: ${colors[suiteType].avatar.accent};
        }

        .st2 {
          fill: ${colors[suiteType].avatar.accent};
        }
      }
    `;

    const { outlined } = this.props;
    const styleToUse = outlined ? avatarOutlinedCss : avatarDefaultCss;

    return (
      <div css={styleToUse}>
        <SVG src={`/suiteAvatars/${suiteType}Avatar.svg`} />
      </div>
    );
  }
}

/**
 * Prop Types
 */
SuiteAvatarAnimated.propTypes = {
  outlined: PropTypes.bool,
  suiteType: PropTypes.string,
  width: PropTypes.string,
};

/**
 * Default Props
 */
SuiteAvatarAnimated.defaultProps = {
  outlined: false,
  suiteType: 'learning',
  width: uc('95px'),
};

export default SuiteAvatarAnimated;
