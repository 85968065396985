/** @jsx jsx */
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Image';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';

/**
 * Renders a quote in a SuiteCard
 *
 * @param text
 *  The quote text to display.
 * @param author
 *  The Author of the quote
 * @param authorRole
 *  The role the Author holds within their company
 * @param logo
 *  The Author's company logo
 * @param icon
 *  An icon to use as the Author's Image
 *
 * @return {*}
 *
 * @constructor
 */
const SuiteCardQuote = ({ _key, author, authorRole, icon, logo, text }) => {
  const authorImageCss = css`
    width: ${uc('45px')};
    height: ${uc('45px')};
    margin-right: ${uc('10px')};
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
    }
  `;

  const quoteCss = css`
    margin-bottom: ${uc('30px')};
    line-height: ${lineHeights.copy};

    &::before {
      content: '“';
      display: inline-block;
    }

    &::after {
      content: '”';
      display: inline-block;
    }
  `;

  const authorTextCss = css`
    margin-bottom: ${uc('5px')};
    font-weight: ${fontWeights.extraBold};
    text-transform: uppercase;

    span {
      padding-right: ${uc('10px')};
    }
  `;

  const authorInfoCss = css`
    width: calc(100% - ${uc('61px')});
  `;

  /**
   * Output.
   */
  return (
    <Fragment key={_key}>
      <Text
        color={colors.darkGray.two}
        css={quoteCss}
        fontSize={fontSizes.fourteen}
      >
        {text}
      </Text>
      <DeprecatedGrid>
        {icon.asset && (
          <div css={authorImageCss}>
            <Image
              src={sanityImage(icon)
                .auto('format')
                .width(45)
                .fit('max')
                .url()}
              alt={`${author} — ${authorRole}`}
            />
          </div>
        )}
        <div css={authorInfoCss}>
          <Text
            color={colors.darkGray.three}
            css={authorTextCss}
            fontSize={fontSizes.twelve}
          >
            <span className="name">{author}</span>
            <span className="role">{authorRole}</span>
          </Text>
          {logo.asset && (
            <Image
              src={sanityImage(logo)
                .auto('format')
                .height(22)
                .fit('max')
                .url()}
            />
          )}
        </div>
      </DeprecatedGrid>
    </Fragment>
  );
};

SuiteCardQuote.propTypes = {
  _key: PropTypes.string,
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  icon: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

SuiteCardQuote.defaultProps = {
  _key: null,
};

export default SuiteCardQuote;
