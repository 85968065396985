/**
 * Renders a SuiteCard that contains a suite avatar, heading, subheading,
 * a client quote, and a button that links to the suite.
 */
/** @jsx jsx */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import SuiteAvatarAnimated from '../SuiteAvatarAnimated/SuiteAvatarAnimated';
import Card from '../../atoms/Card/Card';
import { Heading4 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import Divider from '../../atoms/Dividers/Divider/Divider';
import Link from '../../atoms/Link/Link';
import suiteCardQuote from '../SuiteCardQuote/SuiteCardQuote';
import {
  colors,
  durations,
  fontSizes,
  fontWeights,
  lineHeights,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import list from '../List/List';

/**
 * The <SuiteCard /> Component
 */
class SuiteCard extends Component {
  /**
   * Magic
   *
   * @param props
   *  accentColor {string} : Required
   *  heading {string} : Required
   *  hovered {bool} : default FALSE
   *  productColor {string} : Required
   *  subheading {string} : Required
   */
  constructor(props) {
    super(props);
    this.state = {
      heading: props.heading,
      hovered: props.hovered,
      subheading: props.subheading,
      suiteCardBottomSection: props.suiteCardBottomSection,
      suiteCardLink: props.suiteCardLink,
      suiteType: props.suiteType,
    };
  }

  /**
   * Handle onMouseEnter event
   */
  hoverIn() {
    this.setState({ hovered: true });
  }

  /**
   * Handle onMouseLeave event.
   */
  hoverOut() {
    this.setState({ hovered: false });
  }

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {
      heading,
      hovered,
      subheading,
      suiteCardBottomSection,
      suiteCardLink,
      suiteType,
    } = this.state;

    /**
     * Card link styles
     */
    const linkCss = css`
      text-decoration: none;
    `;
    /**
     * Inner wrapper to apply padding. Unless all <Card> components
     * have the same padding?
     */
    const suiteCardWrapperCss = css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${uc('28px 35px 48px')};

      ul {
        margin-bottom: ${uc('10px')};
        margin-left: ${uc('20px')};
        color: ${colors.mediumGray};
      }

      li {
        p {
          color: ${colors.mediumGray};
          font-size: ${fontSizes.sixteen};
        }
      }

      &:hover ul {
        color: ${colors.accentHover};
        transition: all ${durations.medium} ease-in-out;
      }

      &:hover li p {
        color: ${colors.accentHover};
        transition: all ${durations.medium} ease-in-out;
      }
    `;

    /**
     * Heading styles.
     */
    const headingCss = css`
      max-width: ${uc('150px')};
      margin: ${uc('20px')} 0;
      line-height: ${lineHeights.copy};
      text-align: center;
    `;

    /**
     * Subheading styles.
     */
    const subHeadingCss = css`
      margin-bottom: ${uc('10px')};
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeights.copy};
      text-align: left;
    `;

    /**
     * Change bg color and add box shadow on hover.
     *
     * @type {SerializedStyles}
     */
    const cardHoverCss = css`
      background-color: ${colors.white};
      box-shadow: ${shadows.large};
    `;

    /* Section names must match the Sanity schema name/type. Refer to Section
    field types in backend/schemas/organisms/pages/suitepage.js
   */
    const suiteCardSection = {
      list,
      suiteCardQuote,
    };

    const renderSections = () => {
      const sectionArray = [];
      if (!suiteCardBottomSection) {
        return null;
      }

      // render the component
      suiteCardBottomSection.map(section => {
        sectionArray.push(suiteCardSection[section._type]({ ...section }));
        return sectionArray;
      });
      return sectionArray;
    };

    /**
     * Output
     */
    return (
      <div
        onMouseEnter={this.hoverIn.bind(this)}
        onMouseLeave={this.hoverOut.bind(this)}
      >
        <Link to={suiteCardLink} css={linkCss}>
          <Card
            backgroundColor="transparent"
            hoverStyles={cardHoverCss}
            boxShadow="none"
          >
            <div css={suiteCardWrapperCss}>
              <SuiteAvatarAnimated suiteType={suiteType} outlined={!hovered} />
              <Heading4
                color={colors[suiteType].main}
                css={headingCss}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <Text
                color={colors.mediumGray}
                css={subHeadingCss}
                fontSize={fontSizes.sixteen}
                dangerouslySetInnerHTML={{ __html: subheading }}
              />
              <Divider
                hovered={hovered}
                hoverColor={colors[suiteType].avatar.accent}
                marginTop="10px"
                marginBottom="56px"
                width="60px"
              />
              {renderSections()}
            </div>
          </Card>
        </Link>
      </div>
    );
  }
}

/**
 * Prop types
 */
SuiteCard.propTypes = {
  heading: PropTypes.string.isRequired,
  hovered: PropTypes.bool,
  subheading: PropTypes.string.isRequired,
  suiteCardBottomSection: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.bool,
  ]),
  suiteCardLink: PropTypes.shape({}).isRequired,
  suiteType: PropTypes.string,
};

/**
 * Default props
 */
SuiteCard.defaultProps = {
  hovered: false,
  suiteCardBottomSection: null,
  suiteType: 'learning',
};

export default SuiteCard;
