/**
 * Renders a heading, subheading, and a <SuiteCard /> component for each
 * array item passed in as the cards prop.
 */
/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core'; // eslint-disable-line
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';
import SuiteCard from '../../molecules/SuiteCard/SuiteCard';
import {
  breakpoints,
  colors,
  fontSizes,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import PageWrapper from '../PageWrapper/PageWrapper';
import Section from '../../molecules/Section/Section';

/**
 * Main <SuiteCards /> component
 *
 * @return {*}
 */
const SuiteCards = ({ cards, sectionStyles, subtitle, title }) => {
  const sectionCss = css`
    position: relative;
    z-index: 1;
  `;

  const suiteCardsWrapperCss = css`
    justify-content: center;
    max-width: calc(${pageDimensions.desktopWidth} - ${uc('50px')});
    margin: auto;
    padding: ${uc('40px 10px')};
  `;

  const cardCss = css`
    flex: 1 0 25%;
    width: ${uc('325px')};
    max-width: ${uc('400px')};
    padding: ${uc('10px')};

    @media (${breakpoints.largeTablet}) {
      flex: 1 0 50%;
    }

    @media (${breakpoints.mobile}) {
      flex: 1 0 100%;
    }
  `;

  const headingWrapperCss = css`
    padding-bottom: ${uc('40px')};
    text-align: center;
  `;

  const headingCss = css`
    line-height: ${lineHeights.copy};
  `;

  const subHeadingCss = css`
    max-width: 65%;
    margin: 0 auto;
    font-weight: 500;
    line-height: ${lineHeights.copy};
  `;

  return (
    <Section css={sectionCss} sectionStyles={sectionStyles}>
      <PageWrapper>
        <div css={suiteCardsWrapperCss}>
          {title && subtitle && (
            <div css={headingWrapperCss}>
              <Heading2 css={headingCss} color={colors.darkGray.two}>
                {title}
              </Heading2>
              <Subheading
                color={colors.mediumGray}
                fontSize={fontSizes.twenty}
                css={subHeadingCss}
              >
                {subtitle}
              </Subheading>
            </div>
          )}
          <DeprecatedGrid justifyContent="center">
            {cards.map((card, index) => {
              const cardIndex = `card-${index}`;
              return (
                <div css={cardCss} key={cardIndex}>
                  <SuiteCard {...card} />
                </div>
              );
            })}
          </DeprecatedGrid>
        </div>
      </PageWrapper>
    </Section>
  );
};

/**
 * Prop types
 */
SuiteCards.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

/**
 * Default props
 */
SuiteCards.defaultProps = {
  title: '',
  subtitle: '',
  cards: [],
  sectionStyles: {},
};

export default SuiteCards;
